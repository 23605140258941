var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        {
          ref: "formPurchaseOrderManual",
          attrs: { name: "formPurchaseOrderManual" },
        },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title: _vm.$t("Dados do pedido"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      !_vm.readOnly
                        ? _c("e-store-combo", {
                            attrs: { required: "" },
                            on: { input: _vm.onSelectStore },
                            model: {
                              value: _vm.purchaseOrder.storeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchaseOrder, "storeId", $$v)
                              },
                              expression: "purchaseOrder.storeId",
                            },
                          })
                        : _c("FormulateInput", {
                            attrs: {
                              id: "store-label",
                              type: "label",
                              label: _vm.$t("Loja"),
                            },
                            model: {
                              value: _vm.storeLabel,
                              callback: function ($$v) {
                                _vm.storeLabel = $$v
                              },
                              expression: "storeLabel",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      staticClass: "h-345",
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Dados da Nota Fiscal"),
                      },
                    },
                    [
                      _c("invoice-form", {
                        ref: "invoiceForm",
                        attrs: {
                          "purchase-order": _vm.purchaseOrder,
                          "is-read-only": _vm.readOnly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      staticClass: "h-345",
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Fornecedor"),
                      },
                    },
                    [
                      _c("supplier-form", {
                        ref: "supplierForm",
                        attrs: {
                          "purchase-order": _vm.purchaseOrder,
                          "is-read-only": _vm.readOnly,
                        },
                        on: {
                          "get-supplier-by-id": _vm.getSupplierById,
                          "clean-supplier": _vm.cleanSupplier,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Itens do Pedido"),
                      },
                    },
                    [
                      _c("order-items", {
                        ref: "orderItems",
                        attrs: {
                          "is-xml": false,
                          "purchase-order": _vm.purchaseOrder,
                        },
                        on: {
                          "add-item": _vm.addItem,
                          "remove-item": function (item) {
                            return _vm.removeItem(item)
                          },
                          "update-average-prices":
                            _vm.onUpdateItemsAveragePrices,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Pagamentos"),
                      },
                    },
                    [
                      _c("payments", {
                        ref: "paymentsTable",
                        attrs: { "purchase-order": _vm.purchaseOrder },
                        on: {
                          "add-payment": _vm.addPayment,
                          "remove-payment": function (payment) {
                            return _vm.removePayment(payment)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Anexos"),
                      },
                    },
                    [
                      _c("files-form", {
                        attrs: {
                          files: _vm.files,
                          "is-read-only": _vm.readOnly,
                        },
                        on: {
                          "set-files": _vm.setFiles,
                          "remove-file": _vm.removeFile,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Novo Pedido"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
              busy: _vm.loading,
            },
            on: {
              cancel: _vm.onCancelSubmit,
              "save-completed": function () {
                return _vm.onSubmitOrder(false)
              },
              "save-draft": function () {
                return _vm.onSubmitOrder(true)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }