<template>
  <section>
    <FormulateForm
      ref="formPurchaseOrderManual"
      name="formPurchaseOrderManual"
    >
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="$t('Dados do pedido')"
      >
        <b-row>
          <b-col md="12">
            <e-store-combo
              v-if="!readOnly"
              v-model="purchaseOrder.storeId"
              required
              @input="onSelectStore"
            />
            <FormulateInput
              v-else
              id="store-label"
              v-model="storeLabel"
              type="label"
              :label="$t('Loja')"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row>
        <b-col md="6">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Dados da Nota Fiscal')"
            class="h-345"
          >
            <invoice-form
              ref="invoiceForm"
              :purchase-order="purchaseOrder"
              :is-read-only="readOnly"
            />
          </b-card-actions>
        </b-col>

        <b-col md="6">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Fornecedor')"
            class="h-345"
          >
            <supplier-form
              ref="supplierForm"
              :purchase-order="purchaseOrder"
              :is-read-only="readOnly"
              @get-supplier-by-id="getSupplierById"
              @clean-supplier="cleanSupplier"
            />
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Itens do Pedido')"
          >
            <order-items
              ref="orderItems"
              :is-xml="false"
              :purchase-order="purchaseOrder"
              @add-item="addItem"
              @remove-item="(item) => removeItem(item)"
              @update-average-prices="onUpdateItemsAveragePrices"
            />
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Pagamentos')"
          >
            <payments
              ref="paymentsTable"
              :purchase-order="purchaseOrder"
              @add-payment="addPayment"
              @remove-payment="(payment) => removePayment(payment)"
            />
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Anexos')"
          >
            <files-form
              :files="files"
              :is-read-only="readOnly"
              @set-files="setFiles"
              @remove-file="removeFile"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <fab
        :main-tooltip="$t('Novo Pedido')"
        main-icon="keyboard_command_key"
        :actions="actions"
        :fixed-tooltip="true"
        :busy="loading"
        @cancel="onCancelSubmit"
        @save-completed="() => onSubmitOrder(false)"
        @save-draft="() => onSubmitOrder(true)"
      />
    </FormulateForm>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
// import EButton from '@/views/components/EButton.vue'
import { purchaseOrderDomains, uploader } from '@/mixins'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import fab from '@/views/components/FAB.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import OrderItems from './components/OrderItems.vue'
import Payments from './components/Payments.vue'
import SupplierForm from './components/SupplierForm.vue'
import InvoiceForm from './components/InvoiceForm.vue'
import FilesForm from './components/FilesForm.vue'

export default {
  name: 'PurchaseOrderManualMaintain',

  components: {
    BRow,
    BCol,
    BCardActions,
    OrderItems,
    Payments,
    // EButton,
    SupplierForm,
    InvoiceForm,
    EStoreCombo,
    fab,
    FilesForm,
  },

  mixins: [uploader, purchaseOrderDomains],

  data() {
    return {
      // store: null,
      loading: false,
      loadingSupplier: false,
      showAverageCostDiffWarning: false,
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/purchase/purchaseOrder/purchaseOrderManualMaintain', {
      purchaseOrder: 'purchaseOrder',
      stFiles: 'files',
      stFilesDeleted: 'filesDeleted',
      stItemsPurchase: 'itemsPurchase',
      stAccountPayable: 'accountPayable',
    }),
    ...mapGetters('app', ['storeOptions']),
    readOnly() {
      return this.purchaseOrder.status === 'Completed'
    },
    files: {
      get() {
        return this.stFiles
      },
      set(val) {
        this.setFiles(val)
      },
    },
    hasFile() {
      return this.invoiceXmlFile.length > 0
    },
    isEdit() {
      return !!this.purchaseOrder.id
    },
    hasPendencies() {
      return (
        !!this.purchaseOrder.items.find(item => !item.erpSku?.sku?.id) ||
        !!this.purchaseOrder.payments.find(payment => !payment.managementAccountId)
      )
    },
    storeLabel() {
      return this.purchaseOrder?.store?.name
    },

    actions() {
      return [
        {
          name: 'cancel',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Cancelar'),
        },
        {
          name: 'save-completed',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar'),
          hide: this.readOnly,
        },
        {
          name: 'save-draft',
          icon: 'save_as',
          color: 'green',
          tooltip: this.$t('Salvar como rascunho'),
          hide: this.readOnly,
        },
      ].filter(a => !a.hide)
    },
  },

  created() {
    if (this.$route.params?.id) this.cleanState()
  },

  async mounted() {
    this.fetchManagementAccounts()
    if (this.$route.params?.id) {
      try {
        this.loading = true
        await this.fetchPurchaseOrder(this.$route.params?.id)
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível obter os dados do Pedido de Compra'),
        })
        this.$router.back()
      } finally {
        this.loading = false
      }
    } else if (this.purchaseOrder?.invoiceKey) {
      const keepData = this.readOnly
        ? false
        : await this.confirm({
            title: this.$t('Manter edição anterior?'),
            text: this.$t(
              'Identificamos um Pedido que estava sendo editado anteriormente. Deseja manter estes dados?'
            ),
            confirmButtonText: this.$t('Manter os dados'),
            cancelButtonText: this.$t('Descartar os dados'),
          })
      if (!keepData) {
        this.cleanState()
      }
    }
  },

  methods: {
    ...mapActions('pages/purchase/purchaseOrder', ['fetchManagementAccounts']),
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderManualMaintain', [
      'saveManualPurchaseOrder',
      'saveManualDraftPurchaseOrder',
      'fetchPurchaseOrder',
      'setFiles',
      'cleanSupplier',
      'cleanState',
      'fetchSupplierById',
      'loadItemsAveragePrice',
      'addItem',
      'removeItem',
      'addPayment',
      'removePayment',
      'deleteLocalFile',
      'uploadDocuments',
      'deleteDocuments',
    ]),

    async removeFile(file) {
      this.deleteLocalFile(file)
    },

    onSelectStore(value) {
      if (!value) return
      this.onUpdateItemsAveragePrices(true)
    },

    async onSubmitOrder(isDraft = false) {
      try {
        const error = await this.validateForm()
        if (error.message) {
          this.showInvalidDataMessage({
            message: error.message,
          })
          return
        }

        const confirmed = await this.saveConfirmed(isDraft)

        if (confirmed) {
          this.loading = true
          let data = null
          if (isDraft) {
            data = await this.saveManualDraftPurchaseOrder()
          } else {
            data = await this.saveManualPurchaseOrder()
          }

          await this.actionsAfterSave(data)
        }
      } catch (error) {
        if (!isDraft && Array.isArray(error.response?.data?.Message)) {
          await this.confirmRevision(error.response?.data?.Message[0])
        } else {
          this.showError({ error })
        }
      } finally {
        this.loading = false
      }
    },

    async onCancelSubmit() {
      const isConfirmed =
        this.readOnly ||
        (await this.confirm({
          title: this.$t('Deseja mesmo cancelar?'),
          text: this.$t('Os dados preenchidos serão perdidos'),
        }))
      if (isConfirmed) {
        this.cleanState()
        this.$router.push({ name: 'purchase-order-list' })
      }
    },

    async saveConfirmed(isDraft) {
      let confirmed = false
      if (isDraft) {
        confirmed = await this.confirm({
          icon: 'question',
          title: this.$t('Gravar como Rascunho?'),
          text: this.$t(
            'Deseja gravar como rascunho? Os dados serão gravados, mas nenhuma movimentação de estoque ou preço será realizada.'
          ),
        })
      } else {
        confirmed = await this.confirm({
          title: this.$t('ATENÇÃO: Confirma Gravação?'),
          text: this.$t(
            'Após a gravação, não será permitido realizar nenhuma alteração no Pedido. Estoque e preço médio dos produtos serão atualizados.'
          ),
        })
      }

      return confirmed
    },

    async confirmRevision(errorMessage) {
      const htmlMessage = this.mxGetAverageCostDiffAlertMsg(errorMessage)

      if (!this.showAverageCostDiffWarning) {
        await this.confirmHtml({
          title: this.$t('Atenção'),
          html: htmlMessage,
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
        })
        this.showAverageCostDiffWarning = true
        return
      }

      try {
        const confirmed = await this.confirmHtml({
          title: this.$t('Atenção'),
          html: htmlMessage,
          confirmButtonText: this.$t('Prosseguir, eu já revisei'),
          cancelButtonText: this.$t('Revisar'),
          focusCancel: true,
        })

        if (confirmed) {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.ERP_PURCHASE_ORDER_DIFF_AVERAGE_PRICE,
            this.purchaseOrder?.store?.id
          )
          const data = await this.saveManualPurchaseOrder({
            itemsUnitValueRevision: true,
            tokenDelegated: this.delegateUserData?.token,
          })
          await this.actionsAfterSave(data)
        }
      } catch (error) {
        if (error.name !== 'Cancel') {
          this.showError({ error })
        }
      }
    },

    async actionsAfterSave(data) {
      this.showSuccess({ message: this.$t('Salvo com sucesso.') })

      let hasFileError = false
      try {
        await this.uploadDocuments(data?.id)
      } catch (uploadError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao fazer upload dos anexos'),
          error: uploadError,
        })
        hasFileError = true
      }

      try {
        await this.deleteDocuments(data?.id)
      } catch (deleteDocumentError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao deletar os anexos'),
          error: deleteDocumentError,
        })
        hasFileError = true
      }

      if (!hasFileError && (this.stFiles.some(f => !f.id) || this.stFilesDeleted.length > 0)) {
        this.showSuccess({ message: this.$t('Anexos salvos com sucesso.') })
      }

      this.cleanState()
      this.$router.push({ name: 'purchase-order-list' })
    },

    async validateForm() {
      const error = { message: '' }

      this.$refs.formPurchaseOrderManual.showErrors()
      if (this.$refs.formPurchaseOrderManual.hasErrors) {
        error.message = this.$t('Em Dados do pedido.')
      }
      if (this.$refs.invoiceForm.validate()) {
        error.message = this.$t('Em Dados da nota fiscal')
      } else if (!this.purchaseOrder.supplierId) {
        error.message = this.$t('Em Fornecedor')
      } else if (this.$refs.orderItems.validateForm()) {
        error.message = this.$t('Em items do pedido')
      } else if (this.$refs.paymentsTable.validateForm()) {
        error.message = this.$t('Em pagamentos')
      } else if (this.stFiles.some(f => f.type === null || f.file?.length === 0)) {
        error.message = this.$t('Há anexos incompletos')
      }

      return error
    },

    async getSupplierById(val) {
      try {
        this.loadingSupplier = true
        await this.fetchSupplierById(val)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingSupplier = false
      }
    },

    async onUpdateItemsAveragePrices(toUpdateAll = false) {
      try {
        this.loadingAveragePrices = true

        this.loadItemsAveragePrice({ toUpdateAll })
      } catch (e) {
        this.showWarning({
          message: this.$t('Não foi possível atualizar os custos médios dos itens'),
        })
      } finally {
        this.loadingAveragePrices = false
      }
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}
.h-345 .card {
  min-height: 345px;
}
</style>
